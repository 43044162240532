import Vue from "vue";
import VueRouter from "vue-router";
import appauth from "@/core/auth.js";
import rules from "@/views/home/companyrules";
import companyconfig from "@/core/companyconfig";
import signinorregister from "@/views/signinorregister";
import sociallogin from "@/views/sociallogin";
import forgotpassword from "@/views/forgotpassword";
import notFound from "@/components/shared/notFound";
import welcome from "@/views/welcome";
import layout from "@/views/home/layout";
import home from "@/views/home/home";
import reward from "@/views/home/reward/rewarddetails";
import buyreward from "@/views/home/reward/purchasereward";
import scan from "@/views/home/tasks/scancode";
import receipt from "@/views/home/tasks/receipt";
import invite from "@/views/home/tasks/invitefriend";
import feedback from "@/views/home/tasks/feedback";
import history from "@/views/history";
import { loadedLanguages } from "@/plugins/i18n";
import cashback from "@/views/home/cashback/cashback";
import signupsms from "@/views/signupsms";
import forgotphonepassword from "@/views/forgotphonepassword";
import scoreboard from "@/views/home/optional/scoreboard";
import notifications from "@/views/home/optional/notifications";
import personaldata from "@/views/home/optional/personal_data";
import accounthistory from "@/views/accounthistory";
import uvpredirect from "@/components/shared/uvpRedirect";
import { createRouter, createWebHistory } from 'vue-router'

Vue.use(VueRouter);

const routes = [
    {
        path: "*",
        component: notFound
    },
    {
        path: "/blm",
        name: "blm",
        component: uvpredirect
    },
    {
        path: "/",
        name: "welcome",
        component: welcome
    },
    {
        path: "/login",
        name: "login",
        component: sociallogin
    },
    {
        path: "/signup",
        name: "signup",
        component: signinorregister
    },
    {
        path: "/signupsms",
        name: "signupsms",
        component: signupsms
    },
    {
        path: "/forgotpassword",
        name: "forgotpassword",
        component: forgotpassword
    },
    {
        path: "/forgotphonepassword",
        name: "forgotphonepassword",
        component: forgotphonepassword
    },
    {
        path: "/history",
        name: "history",
        component: history
    },

    {
        path: "/home",
        name: "homelayout",
        component: layout,
        children: [
            {
                path: "rules",
                name: "rules",
                component: rules
            },
            {
                path: "/index",
                name: "home",
                component: home
            },
            {
                path: "/rewards/:type/:id",
                name: "rewardinfo",
                component: reward
            },
            {
                path: "/buyrewards/:id",
                name: "buyreward",
                component: buyreward
            },
            {
                path: "/feedback/:id",
                name: "feedback",
                component: feedback
            },
            {
                path: "/invite/:id",
                name: "invite",
                component: invite
            },
            {
                path: "/scan/:id",
                name: "scan",
                component: scan
            },
            {
                path: "/receipt/",
                name: "receipt",
                component: receipt
            },
            {
                path: "/scoreboard/",
                name: "scoreboard",
                component: scoreboard
            },
            {
                path: "/cashback",
                name: 'cashback',
                component: cashback
            },
            {
                path: "/notifications",
                name: 'notifications',
                component: notifications
            },
            {
                path: "/personaldata",
                name: 'personaldata',
                component: personaldata
            },
            {
                path: "/personalhistory",
                name: "personalhistory",
                component: accounthistory
            }
        ],
        meta: {
            requiresAuth: true
        }
    }
];

const router = new VueRouter({
    base: process.env.NODE_ENV === 'production'
    ? '/'
    : '/',
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        };
    },
    mode: "history",
    routes
});

router.beforeEach((to, from, next) => {
    console.info({from})
    if (to.matched.length === 0) {
        //next({path:"/error"})
        next();
    } else if (to.matched.some(record => record.meta.requiresAuth)) {
        const loggedIn = appauth.isLoggedIn();
        if (!loggedIn) {
            let passedCompanyId = companyconfig.getCompanyIdfromUrl();
            next({
                name: "welcome",
                query: {
                    company_name: passedCompanyId
                }
            });
        } else {
            next();
        }
        // else {
        //   var lang = appauth.getAppLanguage();
        //   loadLanguageAsync(lang).then(() => next()).catch(() => next());
        // }
    } else if (
        (!companyconfig.isCompanySchemeSet() || loadedLanguages.length === 0) &&
        to.name != "welcome"
    ) {
        let passedCompanyId = companyconfig.getCompanyIdfromUrl();
        if (to.name === 'blm' && !appauth.isLoggedIn()) {
            window.location = "https://demo.2take.it/uvp/?cn=" + passedCompanyId;
        } else {
            next({
                name: "welcome",
                query: {
                    company_name: passedCompanyId
                }
            });
        }

    } else {
        next();
    }
});

export default router;

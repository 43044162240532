<template>
  <v-container>
    <v-row class="mb-6" v-if="history.length > 0">
      <v-col cols="12">
        <v-card
            :key="'purchase' + purchase.id"
            class="mx-auto my-12 mb-4"
            max-width="374"
            v-for="purchase in history"
        >
          <template slot="progress">
            <v-progress-linear
                color="red"
                height="10"
                indeterminate
            ></v-progress-linear>
          </template>

          <v-img
              style="width: 100%"
              :src="purchase.product.picture"></v-img>

          <v-card-title>{{ purchase.product.name }}</v-card-title>

          <v-card-text>
            <v-row
                align="center"
                class="mx-0">
              <div class="black--text">
                Zamówienie <strong>{{purchase.id}}</strong> zostało złożone o {{ purchase.orderTime }}
                Company {{purchase.company.name}}
                {{ getPurchaseBy(purchase) }}
              </div>
              <div class="black--text" v-if="purchase.product.bought_info.length > 0 && bought_info_statuses.includes(purchase.status)">
                {{ purchase.bought_info }}
              </div>


              <div class="black--text" >
                {{ purchase.bought_info }}
              </div>

              <p class="reward-history-address" v-if="!purchase.product.is_virtual && purchase.product.need_accept == 0">
                Order address: {{ purchase.full_address }}
              </p>
            </v-row>

          </v-card-text>
        </v-card>

        <template v-if="hasMore">
          <v-btn block @click="loadHistory">
            Pokaż więcej zapisów
          </v-btn>
        </template>

      </v-col>
    </v-row>
    <v-row class="mb-6" v-else>
      <v-card-title>
        Nie znaleziono żadnych zakupów
      </v-card-title>
    </v-row>
  </v-container>
</template>

<script>
import {NO_HISTORY, NO_COMPANY_FOUND} from "@/appConstants";
import companyconfig from "@/core/companyconfig";
import auth from "@/core/auth";
import api from "@/services/fetchapi";

export default {
  data() {
    return {
      history: [],
      hasMore: false,
      limit: 10,
      offset: 0,

      status_map: [],
      bought_info_statuses: ['W','S','Z'],
    };
  },
  created() {
    this.loadHistory();
  },
  methods: {
    loadHistory() {
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      let token = auth.getAccessToken();
      let instance = this;
      api
          .getPurchasesHistory(token, passedCompanyId, instance.limit, instance.offset)
          .then(result => {
            let response = result.data;

            response.receipts.forEach((item) => {
              instance.history.push(item);
            });

            instance.status_map = response.status_map;
            instance.hasMore = response.hasMore;
            instance.offset += instance.limit;
          })
          .catch(err => {
            console.log(err);

            this.historyExists = false;
            let errmsg = err.data.message;
            if (errmsg === NO_HISTORY) {
              console.log(err);
            } else if (errmsg === NO_COMPANY_FOUND) {
              console.log(err);
            }
          });
    },
    getStatusColor(receipt) {
      let $color = '#aba204';
      switch (receipt.is_success) {
        case '-1':
          $color = '#f44336';
          break;
        case '0':
          if (parseInt(receipt.marked_by_user_to_recheck) > 0) {
            $color = '#aba204';
          } else {
            $color = '#f44336';
          }
          break;
        case '2':
        case '1':
          $color = '#00d415';
          break;
      }
      return $color;
    },
    getStatusText(receipt) {
      let $status = '';
      switch (receipt.is_success) {
        case '-1':
          $status = 'Odrzucony';
          break;
        case '0':
          if (parseInt(receipt.marked_by_user_to_recheck) > 0) {
            $status = 'Oczekuje na weryfikację';
          } else {
            $status = 'Odrzucony';
          }
          break;
        case '2':
        case '1':
          $status = 'Zaakceptowany';
          break;
      }
      return $status;
    },
    getPurchaseBy(purchase) {
      let r = '';
      if(purchase.product.need_accept != 2 || purchase.status === 'Z' || purchase.status === 'S') {
        r = 'o wartości ' + purchase.price + ' ' + this.lang_points(purchase.price);
      }
      return r;
    },
    lang_points(num, base, suffix_1, suffix_2, suffix_5) {
      if (typeof base === 'undefined') base = 'punkt';
      if (typeof suffix_1 === 'undefined') suffix_1 = '';
      if (typeof suffix_2 === 'undefined') suffix_2 = 'y';
      if (typeof suffix_5 === 'undefined') suffix_5 = 'ów';
      num = 1*num;
      var rnum = Math.round(num);
      if (num != rnum) return base + suffix_5; // fraction
      if (num == 0) return base + suffix_5;
      if (num == 1) return base + suffix_1;
      var last_num = num % 10;
      if 	(last_num > 1 && last_num < 5 && (num < 10 || num > 20)) return base + suffix_2;
      else return base + suffix_5;
    }
  }
};
</script>
<style>

</style>

<template>
  <v-container>
    <p
      v-show="isErrorCompany"
      v-text="$t('Errors.FAILED_TO_LOAD_COMPANY_ISSUE')"
    ></p>
    <p v-show="isError" v-text="$t('Errors.FAILED_TO_LOAD')"></p>
  </v-container>
</template>
<script>
import api from "@/services/companyapi";
import fapi from "@/services/fetchapi";
import auth from "@/core/auth.js";
import companyconfig from "@/core/companyconfig";
import config from "@/variable";
import { loadLanguageAsync } from "@/plugins/i18n";
import Vue from "vue";
export default {
  data() {
    return {
      isLoaded: false,
      isError: false,
      isErrorCompany: false
    };
  },
  methods: {
    processUrlParams () {
      const passedCompanyName = companyconfig.getCompanyIdfromUrl();
      if (this.$route.query.bot_fbhash) {
        companyconfig.setUrlParamToLocalStorage(passedCompanyName, 'bot_fbhash', this.$route.query.bot_fbhash);
      }

      if (this.$route.query.bot_fbid) {
        companyconfig.setUrlParamToLocalStorage(passedCompanyName, 'bot_fbid', this.$route.query.bot_fbid);
      }
    },
    /*
    sendRegistrationEventGtag () {
      if (typeof window.gtag != 'function') {
        setTimeout(this.sendRegistrationEventGtag, 200);
        return;
      }
      window.gtag('event', 'conversion', {'send_to': 'AW-298953983/U1FTCLTc-qMDEP_Zxo4B'});
    },*/
    /** Load the company config and save to localstorage  */
    processCompany() {
      this.processUrlParams();
      /*
      if (companyconfig.getIsFirst()) {
        this.sendRegistrationEventGtag ();
      }*/
      const passedCompanyId = companyconfig.getCompanyIdfromUrl();
      let isLegacy = false;
      if (this.$route.query.legacy) {
        companyconfig.setLoginLegacyMode(passedCompanyId);
        isLegacy = true;
      }
      api
        .getCompanyInfo(passedCompanyId, isLegacy)
        .then(result => {
          companyconfig.setCompanyScheme(result.data, passedCompanyId);
          if (result.data.data.id) {
            Vue.localStorage.set("company_id", result.data.data.id); //NEEDED FOR "SUPPORTBOARD" CHAT SYNCHRONIZATION
            Vue.localStorage.set("vuels__2tiCompanyId", JSON.stringify({value: result.data.data.id})); //NEEDED FOR "SUPPORTBOARD" CHAT SYNCHRONIZATION
            Vue.localStorage.set("vuels__2tiCompanyName", JSON.stringify({value: result.data.data.url_name})); //NEEDED FOR "SUPPORTBOARD" CHAT SYNCHRONIZATION
            console.log('>444');
          }
          this.isLoaded = true;
          this.doRedirect();
        })
        .catch(err => {
          let msg = err.data.message;
          if (msg === "NO_COMPANY_FOUND") {
            this.isError = false;
            this.isErrorCompany = true;
          } else {
            this.isError = true;
            this.isErrorCompany = false;
          }
          this.console.log(err); //redirect to error page no company found
        });
    },
    /** Check company name changed in same tab */
    checkCompanyChanged(callback) {
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      let companyId = companyconfig.getCompanyId();
      if (companyId === "" || passedCompanyId != companyId) {
        companyconfig.resetCompanyLocals(companyId);
        this.processCompany();
        callback();
      } else {
        callback();
      }
      this.processCompany();
      callback();
    },
    /** Do the redirect either if logged in */
    doRedirect() {
      let islogged = auth.isLoggedIn();
      let currentroute = this.$route.name;
      console.log(this.$route);
      console.log(window.location);
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      if (!islogged) {
        if (currentroute != "login") {
          this.$router.push({
            name: "login",
            query: { company_name: passedCompanyId }
          });
        }
      } else {
        if (
          currentroute === "welcome" ||
          currentroute === "login" ||
          currentroute === "signup" ||
          currentroute === "forgotpassword"
        ) {
          let token = auth.getAccessToken();
          let lang = this.$i18n.locale;
          if (fapi.hasAnyRules(token, passedCompanyId, lang)) {
            this.$router.push({
              name: "rules",
              query: { company_name: passedCompanyId }
            });
          } else {
            this.$router.push({
              name: "home",
              query: { company_name: passedCompanyId }
            });
          }
        } else {
          this.$router.push({
            name: currentroute,
            query: { company_name: passedCompanyId }
          });
        }
      }
    },
    /** Check the color and language configs are loaded */
    checkAuthenticatedWithConfigLoaded() {
      let isConfigLoaded = companyconfig.isCompanySchemeSet();
      let islogged = auth.isLoggedIn();
      if (islogged && isConfigLoaded) {
        this.doRedirect();
      } else if (!isConfigLoaded) {
        this.isLoaded = true;
        this.processCompany();
      } else if (isConfigLoaded) {
        this.isLoaded = true;
        this.doRedirect();
      }
    },
    /** Check company name is valid else show the error */
    checkValidCompany() {
      this.processUrlParams();
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      let self = this;
      let isLegacy = false;
      if (this.$route.query.legacy) {
        companyconfig.setLoginLegacyMode(passedCompanyId);
        isLegacy = true;
      }
      api
        .isValidCompany(passedCompanyId, isLegacy)
        .then(function(response) {
          if (response === true) {
            self.checkCompanyChanged(self.checkAuthenticatedWithConfigLoaded);
          } else {
            self.isLoaded = true;
            self.isError = false;
            self.isErrorCompany = true;
          }
        })
        .catch(() => {
          self.isLoaded = true;
          self.isError = false;
          self.isErrorCompany = true;
        });
    },
    /** Get the company name from url else show error */
    checkUrl(callback) {
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      if (passedCompanyId === "") {
        this.isLoaded = true;
        this.isError = false;
        this.isErrorCompany = true;
      } else {
        callback();
      }
    },
    /** Load the default browser language strings and save in local storage */
    loadLanguageJSON(callback) {
      let lang = auth.getAppLanguage();
      let data = companyconfig.getCompanyScheme();

      let url_name = data.url_name;
      if (!url_name) {
        let url_string = window.location.href;
        let url = new URL(url_string);
        url_name = url.searchParams.get("company_name");
      }

      loadLanguageAsync(lang, url_name)
        .then(() => {
          callback(this.checkValidCompany);
        })
        .catch(err => {
          this.isLoaded = true;
          this.isError = false;
          console.log(err);
        });
    },
    /** Load the company based css files  */
    appendCompanyCss() {
      let ss = document.styleSheets;
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      let companycss = passedCompanyId + ".css";
      for (let i = 0, max = ss.length; i < max; i++) {
        let href = ss[i].href;
        if (href && href.indexOf(companycss) > 0) return;
      }
      let file = document.createElement("link");
      file.rel = "stylesheet";
      file.href = config.baseUrl + "company_css/" + passedCompanyId + ".css";
      document.head.appendChild(file);
    }
  },
  created() {
    //this.appendCompanyCss();
    this.loadLanguageJSON(this.checkUrl);
  }
};
</script>

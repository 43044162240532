var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-toolbar',{style:(`color:${_vm.config.mfontcolor} !important`),attrs:{"flat":"","dense":"","color":_vm.config.mcolor}},[_c('v-btn',{staticClass:"border0px",style:(`color:${_vm.config.mfontcolor} !important`),attrs:{"icon":""},on:{"click":_vm.navigate}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',{domProps:{"textContent":_vm._s(_vm.$t('RewardsScreen.BUY') + ' ' + _vm.product.name)}}),_c('v-spacer'),_c('v-btn',{staticClass:"border0px",style:(`color:${_vm.config.mfontcolor} !important`),attrs:{"icon":""},on:{"click":_vm.navigatehome}},[_c('v-icon',[_vm._v("mdi-home-circle-outline")])],1)],1),(!_vm.showSuccess)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"col-12"},[_c('v-form',{ref:"addrForm",model:{value:(_vm.address.valid),callback:function ($$v) {_vm.$set(_vm.address, "valid", $$v)},expression:"address.valid"}},[_c('v-text-field',{attrs:{"autocomplete":"off","filled":"","dense":"","placeholder":'Imię i nazwisko',"name":"full_name","prepend-icon":"person","type":"text","autofocus":"","rules":[
                value => {
                  return _vm.purchasefullnamerule(value);
                }
              ]},model:{value:(_vm.address.full_name),callback:function ($$v) {_vm.$set(_vm.address, "full_name", $$v)},expression:"address.full_name"}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"xs6":""}},[_c('v-text-field',{attrs:{"autocomplete":"off","filled":"","dense":"","placeholder":'Numer domu',"name":"addrAprtment","prepend-icon":"mdi-home-floor-0","type":"text"},model:{value:(_vm.address.apartment_number),callback:function ($$v) {_vm.$set(_vm.address, "apartment_number", $$v)},expression:"address.apartment_number"}})],1),_c('v-col',{attrs:{"xs6":""}},[_c('v-text-field',{attrs:{"autocomplete":"off","filled":"","dense":"","placeholder":'Numer mieszkania',"name":"addrStreet","prepend-icon":"mdi-home-account","type":"text"},model:{value:(_vm.address.street_number),callback:function ($$v) {_vm.$set(_vm.address, "street_number", $$v)},expression:"address.street_number"}})],1)],1),_c('v-text-field',{attrs:{"autocomplete":"off","filled":"","dense":"","placeholder":'Ulica',"name":"address","prepend-icon":"mdi-home-map-marker","type":"text"},model:{value:(_vm.address.street_address),callback:function ($$v) {_vm.$set(_vm.address, "street_address", $$v)},expression:"address.street_address"}}),_c('v-text-field',{attrs:{"autocomplete":"off","filled":"","dense":"","placeholder":'Miejscowość',"name":"addrCity","prepend-icon":"mdi-home-city","type":"text"},model:{value:(_vm.address.city),callback:function ($$v) {_vm.$set(_vm.address, "city", $$v)},expression:"address.city"}}),_c('v-text-field',{attrs:{"autocomplete":"off","filled":"","dense":"","placeholder":'Kraj',"name":"addrProvince","prepend-icon":"mdi-globe-model","type":"text"},model:{value:(_vm.address.country),callback:function ($$v) {_vm.$set(_vm.address, "country", $$v)},expression:"address.country"}}),_c('v-text-field',{attrs:{"autocomplete":"off","filled":"","dense":"","placeholder":'Kod pocztowy',"name":"addrZip","prepend-icon":"mdi-home-map-marker","type":"text","rules":[
                value => {
                  return _vm.purchasezipcoderule(value);
                }
              ]},model:{value:(_vm.address.zip_code),callback:function ($$v) {_vm.$set(_vm.address, "zip_code", $$v)},expression:"address.zip_code"}}),_c('alert',{attrs:{"errorType":_vm.address.errtype,"errorMessage":_vm.address.errors,"alert":_vm.address.showerror}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"block":"","color":"primary","loading":_vm.address.loading,"disabled":_vm.address.loading},domProps:{"textContent":_vm._s(_vm.$t('RewardsScreen.PURCHASE_BTN'))},on:{"click":_vm.doPurchase}})],1)],1):_vm._e(),(_vm.showSuccess)?_c('v-layout',{staticClass:"mt-12",attrs:{"align-center":"","justify-center":""}},[_c('v-card',{staticClass:"text-center pt-10",attrs:{"elevated":"","raised":"","width":"350"}},[_c('v-icon',{attrs:{"height":"150px","size":"64","color":"green"}},[_vm._v("mdi-check-circle-outline")]),_c('v-card-title',{staticClass:"font-weight-bold justify-center",domProps:{"textContent":_vm._s(_vm.$t('RewardsScreen.PURCHASE_SUCCESSFUL'))}}),_c('v-card-actions',[_c('v-btn',{staticClass:"mt-5",style:(`color:${_vm.config.mfontcolor} !important`),attrs:{"block":"","color":"secondary"},domProps:{"textContent":_vm._s(_vm.$t('RewardsScreen.GOTO_HOME'))},on:{"click":_vm.navigatehome}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card>
    <v-toolbar-title class="my-3 ml-2">{{
      $t("RulesScreen.TITLE")
    }}</v-toolbar-title>
    <v-list three-line>
      <v-list-item-group>
        <div v-for="(rule, index) in comprules" :key="index">
          <v-list-item :key="index" @click="rule.selected = !rule.selected">
            <v-list-item-action>
              <v-checkbox
                :value="rule.selected"
                v-model="rule.selected"
                @click="rule.selected = !rule.selected"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content class="pt-0">
              <v-list-item-title v-if="rule.title">
                {{ rule.title }}
              </v-list-item-title>
              <v-list-item-subtitle>{{
                rule.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index + 1 < comprules.length"></v-divider>
        </div>
      </v-list-item-group>
    </v-list>
    <v-flex class="justify-center">
      <v-btn
        block
        :color="config.pcolor"
        :style="`color:${config.pfontcolor} !important`"
        @click="doAccept"
        v-text="$t('LoginScreen.ACCEPT_BTN')"
      ></v-btn>
    </v-flex>
  </v-card>
</template>
<script>
import { NO_COMPANY_FOUND } from "@/appConstants";
import companyconfig from "@/core/companyconfig";
import auth from "@/core/auth";
import api from "@/services/fetchapi";
export default {
  data() {
    return {
      comprules: [],
      config: {},
      index: 0
    };
  },
  mounted() {
    this.setupLayout();
    this.loadRules();
  },
  methods: {
    /**
     * Set the Intial color configuration for page
     */
    setupLayout() {
      let data = companyconfig.getCompanyScheme();
      if (data != "") {
        this.$set(this.config, "pcolor", data.main_color);
        this.$set(this.config, "pfontcolor", data.main_font_color);
      }
    },
    /** Accept the rules */
    doAccept() {
      let items = this.comprules.filter(a => a.selected == true);
      if (items.length !== 0) {
        let ids = items.map(e => e.id).join(",");
        let passedCompanyId = companyconfig.getCompanyIdfromUrl();
        let token = auth.getAccessToken();
        let lang = this.$i18n.locale;
        api
          .agreeCompanyRules(token, passedCompanyId, lang, ids)
          .then(() => {
            this.movetoIndex();
          })
          .catch(() => {
            this.movetoIndex();
          });
      } else {
        this.movetoIndex();
      }
    },
    /** Navigate to home page */
    movetoIndex() {
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      this.$router.push({
        name: "home",
        query: { company_name: passedCompanyId }
      });
    },
    /** Load the rules */
    loadRules() {
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      let token = auth.getAccessToken();
      let lang = this.$i18n.locale;
      api
        .getCompanyRules(token, passedCompanyId, lang)
        .then(result => {
          let response = result.data;
          if (Object.keys(response.data).length != 0) {
            let rules = response.data;
            rules.forEach(element => {
              element.selected = true;
            });
            this.comprules = rules;
          } else {
            this.movetoIndex();
          }
        })
        .catch(err => {
          let errormsg = err.data.message;
          if (errormsg === NO_COMPANY_FOUND) {
            console.log(err);
          }
          this.movetoIndex();
        });
    }
  }
};
</script>

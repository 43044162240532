<template>
  <v-footer
      class="mt-4"
      absolute
      :style="`color:${config.mfontcolor} !important;`"
  >
    <div style="width:100%">
      <p style="text-align: center; font-size: 12px; margin: 0;">powered by <a id="powered" class="black--text"
                                                                               :href="info.link"
                                                                               target="_blank">{{info.title}}</a></p>
    </div>
  </v-footer>

</template>

<script>

import companyconfig from "@/core/companyconfig";
import Vue from "vue";

export default {
  data() {
    return {
      config: {},
      partners: {
        default: {
          link: 'https://blovly.com?utm_source=footer',
          title: 'Blovly.com',
        },

        c_gence: {
          link: 'https://www.c-gence.com/?utm_source=footer',
          title: 'C-gence',
        },
      },
      info: {}
    };
  },
  created() {
    this.getPartnerData();
  },
  methods: {
    getPartnerData() {
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();

      let partner_company = '';

      if(passedCompanyId === 'skanuj-wygrywaj') {
        partner_company = 'c_gence';
      }

      if(partner_company in this.partners) {
         this.info = this.partners[partner_company];
         return;
      }
      this.info = this.partners.default;
    },
    setupLayout() {
      let data = companyconfig.getCompanyScheme();
      if (data != "") {
        Vue.set(this.config, "mfontcolor", data.main_font_color);
      }
    },
  }
};
</script>

<style>
footer {
  z-index: 0;
}
</style>

import Vue from "vue";
import vuetify from "./plugins/vuetify";
import "./plugins/vuestorage";
import "./plugins/socialplugins";
import "./plugins/clipboard";
import App from "./App.vue";
import router from "./router";
import { i18n } from "./plugins/i18n";
import * as Sentry from "@sentry/vue";
import * as Integrations from '@sentry/integrations';
import { BrowserTracing } from "@sentry/tracing";
import './registerServiceWorker'

require('./assets/css/styles.css');
Vue.config.productionTip = false;

Sentry.init({
  Vue,
  dsn: "https://70c8917ea53f4f88b4c16a71e255514b@o1232060.ingest.sentry.io/6379894",
  integrations: [
    new Integrations.Vue({
      Vue,
      attachProps: true,
      logErrors: true
    }),
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  tracesSampleRate: 1.0, // 1.0 = 100%
});

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
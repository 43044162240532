<template>
  <v-app v-scroll="onScroll">
    <router-view></router-view>
    <v-fab-transition>
      <v-btn
          color="blue darken-3"
          dark
          fab
          fixed
          bottom
          :right="showFabRight"
          :left="!showFabRight"
          v-show="showFab"
          @click="$vuetify.goTo('#app', { duration: 500, offset: 0 })"
      ><v-icon>keyboard_arrow_up </v-icon>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>
<script>
import { INVALID_REFRESH_TOKEN } from "@/appConstants";
import auth from "@/core/auth";
import companyconfig from "@/core/companyconfig";
import { loadedLanguages } from "@/plugins/i18n";
import api from "@/services/loginapi";
import { loadLanguageAsync } from "@/plugins/i18n";
import Vue from "vue";
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
Vue.prototype.$token = urlParams.get('token');
Vue.prototype.$cn = urlParams.get('company_name');

export default {
  name: "App",
  data() {
    return {
      timer: "",
      offsetTop: 0,
      showFab: false,
      config: {},
      showFabRight: false
    };
  },
  created() {
    companyconfig.configVuetify(this.$vuetify);
    this.setupLayout();
    this.settimer(5000);
    this.loadMessagesOnScreenRefresh();

    this.saveGuestHash();
  },
  methods: {
    /**
     * Do the scroll and show the fab icon, which scroll to top
     */
    onScroll(e) {
      this.offsetTop =
          e.target.documentElement.scrollTop || e.currentTarget.scrollY;
      if (this.offsetTop > 100) {
        //Krystian asked to disable FAB icon... so I just commenting out it now, I know that in some time he will request it back again.... do not thank:)
        // this.showFab = true;
      } else {
        this.showFab = false;
      }
    },
    /**
     * Fetch the refresh token when token expires, support for interceptor
     */
    fetchRefreshToken() {
      if (auth.isLoggedIn() && auth.isTokenExpired()) {
        let rToken = auth.getIdToken();
        let self = this;
        return api
            .doRefreshToken(rToken)
            .then(function(success) {
              let data = success.data;
              if (success.message === INVALID_REFRESH_TOKEN) {
                let passedCompanyId = companyconfig.getCompanyIdfromUrl();
                this.$router.push({
                  name: "welcome",
                  query: {
                    company_name: passedCompanyId
                  }
                });
                auth.clearTokens();
                self.clearTimeout();
              } else if (data) {
                auth.setTokens(
                    data.token,
                    data.refresh_token,
                    data.expiry_second
                );
                self.settimer(5000);
              }
            })
            .catch(function(error) {
              console.log("Refresh login error: ", error);
            });
      }
      this.settimer(5000);
    },
    /** On logout cancel the timer */
    cancelAutoUpdate() {
      clearTimeout(this.timer);
    },
    /** Intialize the timer */
    settimer(mlsec) {
      this.timer = setTimeout(() => {
        this.fetchRefreshToken();
      }, mlsec);
    },
    /**
     * Fetch the language when the user presee f5 on the browser
     */
    loadMessagesOnScreenRefresh() {
      if (loadedLanguages.length === 0) {
        let lang = auth.getAppLanguage();
        let data = companyconfig.getCompanyScheme();

        let url_name = data.url_name;
        if (!url_name) {
          let url_string = window.location.href;
          let url = new URL(url_string);
          url_name = url.searchParams.get("company_name");
        }

        loadLanguageAsync(lang, url_name).then(() => {});
      }
    },
    /*
     * Set the Intial color configuration for page
     */
    setupLayout() {
      let data = companyconfig.getCompanyScheme();
      if (data != "") {
        if (data.taskfabposition == "left") {
          this.showFabRight = true;
        } else {
          this.showFabRight = false;
        }

        if(data.layout_custom_js != '') {
          var new_script = document.createElement("script");
          new_script.appendChild(data.layout_custom_js);
          document.body.appendChild(new_script);
        }
      }
    },
    saveGuestHash(){
      var url = window.location.href;
      var captured = /guest_hash=([^&]+)/.exec(url);
      var result = captured ? captured[1] : false;
      if(result) {
        localStorage.setItem('guest_hash', result);
      }
    },
    get_top_domain(){
      var i,h,
          weird_cookie='weird_get_top_level_domain=cookie',
          hostname = document.location.hostname.split('.');
      for(i=hostname.length-1; i>=0; i--) {
        h = hostname.slice(i).join('.');
        document.cookie = weird_cookie + ';domain=.' + h + ';';
        if(document.cookie.indexOf(weird_cookie)>-1){
          // We were able to store a cookie! This must be it
          document.cookie = weird_cookie.split('=')[0] + '=;domain=.' + h + ';expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          return h;
        }
      }
    }
  },
  mounted() {
    document.domain = this.get_top_domain();
    console.log('new_loyalty document.domain=' + document.domain)
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  }
};
</script>

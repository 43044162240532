<template>
  <v-card flat tile>
    <v-toolbar
        dense
        :color="config.mcolor"
        :style="`color:${config.mfontcolor} !important`"
        flat
    >
      <v-btn
          icon
          class="border0px"
          @click="navigate"
          :style="`color:${config.mfontcolor} !important`"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title v-text="'Historia'"></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
          icon
          class="border0px"
          @click="navigatehome"
          :style="`color:${config.mfontcolor} !important`"
      >
        <v-icon>mdi-home-circle-outline</v-icon>
      </v-btn>
    </v-toolbar>

    <v-tabs
        v-model="tab"
        fixed-tabs
        color="blue-grey lighten-3">

      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#tab-1">
        Rachunki
        <v-icon>mdi-receipt-text-check</v-icon>
      </v-tab>

      <v-tab href="#tab-2">
        Nagrody
        <v-icon>mdi-gift</v-icon>
      </v-tab>

    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item :key="1" :value="'tab-1'">
        <receipts></receipts>
      </v-tab-item>
      <v-tab-item :key="2" :value="'tab-2'">
        <purchases></purchases>
      </v-tab-item>
    </v-tabs-items>

  </v-card>
</template>
<script>
import { NO_HISTORY, NO_COMPANY_FOUND } from "@/appConstants";
import companyconfig from "@/core/companyconfig";
import auth from "@/core/auth";
import api from "@/services/fetchapi";
import receipts from "../components/history/receipts";
import games from "../components/history/games";
import purchases from "../components/history/purchases";

export default {
  data() {
    return {
      history: [],
      config: {},
      tab: 1,
      colors: [
        "cyan",
        "green",
        "purple",
        "amber",
        "pink",
        "teal",
        "indigo",
        "light-green",
        "light-blue",
        "blue-grey",
        "brown"
      ],
      historyExists: false
    };
  },
  components: {
    receipts,
    games,
    purchases
  },
  mounted() {
    this.setupLayout();
    // this.loadHistory();
  },
  methods: {
    /**
     * Set the Intial color configuration for page
     */
    setupLayout() {
      let data = companyconfig.getCompanyScheme();
      if (data != "") {
        this.$set(this.config, "mcolor", data.main_color);
        this.$set(this.config, "mfontcolor", data.main_font_color);
      }
    },
    /** Navigate to previous url */
    navigate() {
      this.$router.go(-1);
    },
    /** Navigate to home page */
    navigatehome() {
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      this.$router.push({
        name: "home",
        query: { company_name: passedCompanyId }
      });
    },
    /** Load the user entire history */
    loadHistory() {
      let passedCompanyId = companyconfig.getCompanyIdfromUrl();
      let token = auth.getAccessToken();
      //let lang = this.$i18n.locale;
      api
          .getHistory(token, passedCompanyId)
          .then(result => {
            let response = result.data;
            let items = response.data;
            let data = [];
            if (items.length != 0) {
              items.forEach((item, index) => {
                let obj = {};
                console.log(index);
                if (item.points < 0) {
                  obj.mcolor = "red";
                } else {
                  obj.mcolor = "green";
                }
                obj.points = item.points + " " + this.$t("AppScreen.POINTS");
                obj.type = this.getType(item.type);
                obj.date = item.date;
                obj.color = this.colors[
                    Math.floor(Math.random() * this.colors.length)
                    ];
                data.push(obj);
              });
              this.history = data;
              this.historyExists = true;
            } else {
              this.historyExists = false;
            }
          })
          .catch(err => {
            this.historyExists = false;
            let errmsg = err.data.message;
            if (errmsg === NO_HISTORY) {
              console.log(err);
            } else if (errmsg === NO_COMPANY_FOUND) {
              console.log(err);
            }
          });
    },
    /** Get the type of history information which are displayed */
    getType(type) {
      let op = "";
      switch (type) {
        case "S":
          op = this.$t("AppScreen.SURVEY_ANSWERED");
          break;
        case "SV":
          op = this.$t("AppScreen.SURVEY_VIEWED");
          break;
        case "BAR":
          op = this.$t("AppScreen.BARCODE_SCANNED");
          break;
        case "RRU":
          op = this.$t("AppScreen.REGISTRATION_WITH_INVITE_CODE");
          break;
        case "BAP":
          op = this.$t("AppScreen.BOUGHT_A_PRICE");
          break;
      }
      return op;
    }
  }
};

//  S = survey answered
//             SV = survey viewed
//             BAR = barcode scanned
//             RRU = Registration with invitation code
//             BAP = Bought A Price
</script>
